<template>
    <thead :class="classes">
        <slot />
    </thead>
</template>

<script>
export default {
    data() {
        return {
            baseClasses: "table-head py-3.5 text-left text-sm text-gray-900 bg-light",
        };
    },
    computed: {
        classes() {
            return `table-head ${this.baseClasses}`;
        },
    },
};
</script>
